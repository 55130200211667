import React, { Component } from "react";
import { FaTwitter } from "react-icons/fa";
import { GoRss, GoOctoface, GoMail } from "react-icons/go";

import "./UserLinks.css";

class UserLinks extends Component {
  getLinkElements() {
    const { userLinks } = this.props.config;
    const { labeled } = this.props;
    return userLinks.map(link => (
      <a className="userlink" key={link.label} href={link.url}>
        {link.label == 'GitHub' && 
          <GoOctoface />
        } 
        {link.label == 'Twitter' && 
          <FaTwitter />
        }
        {link.label == 'Email' && 
          <GoMail />
        }
        &nbsp;
      </a>
    ));
  }
  render() {
    const { userLinks } = this.props.config;
    if (!userLinks) {
      return null;
    }
    return this.getLinkElements();
  }
}

export default UserLinks;
