import React from "react";
import { Link } from "gatsby";
import moment from 'moment';
import "./PostListing.css"
class PostListing extends React.Component {
  getPostList() {
    const postList = [];
    this.props.postEdges.forEach(postEdge => {
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.fields.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead
      });
    });
    return postList;
  }
  render() {
    const postList = this.getPostList();
    return (
      <table className='post-list'><tbody>
        {/* Your post list here. */
        postList.map(post => (
          // <li key={post.title}>
          // <Link to={post.path} >
          //   <span>{post.title}</span>
          // </Link>
          // </li>

          <tr key={post.title}>
            <td className="dateColumn"><time>{moment(post.date).format('YYYY-MM-DD')}</time></td>
            <td><Link to={`/${post.path}`}>{post.title}</Link></td>
          </tr>
        ))
        }
        
        </tbody></table>
    );
  }
}

export default PostListing;
