import React from "react";
import Link from "gatsby-link"
import BigHeader from "../components/Header/BigHeader";
import Helmet from "react-helmet";
import Footer from '../components/Footer/Footer';
import config from "../../data/SiteConfig";
import "./index.css";
import typography from "../utils/typography"
const rhythm = typography.rhythm
const scale = typography.scale

export default class MainLayout extends React.Component {
  render() {
    const bgColors = { "Dark": "#333",
                    "Light": "#fff",
    };

    let header
    // Check if the location is either the front page or a tags page.
    // If so, use a big header, otherwise use a smaller one.
      header = (
        <Link
          style={{
            textDecoration: "none",
            boxShadow: "none",
            color: "inherit",
          }}
          to="/"
        >
          <h1
            style={{
              ...scale(1),
              marginBottom: rhythm(1),
              background:bgColors.Cyan,
              color:bgColors.Default,
              marginTop: 0,
            }}
          >
            {config.siteTitle}
          </h1>
        </Link>
      )
   const { children } = this.props;
    return (
      <div 
      style={{
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        maxWidth: rhythm(25),
        margin: `0 auto`,
      }}
    
      >
        <Helmet defaultTitle={config.siteTitle} titleTemplate="%s">
          <meta name="description" content={config.siteDescription} />
        </Helmet>

        {children}
        <Footer />
      </div>
    );
  }
}
