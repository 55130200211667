import React, { Component } from "react";
import { Link } from "gatsby";
import siteConfig from "../../../data/SiteConfig";
import UserLinks from "../UserLinks/UserLinks";
import { GoRss } from "react-icons/go";
import "./Footer.css";

class Footer extends Component {
  render() {
    const { config } = this.props;
    const url = siteConfig.siteRss;
    const { copyright } = siteConfig;
    if (!copyright) {
      return null;
    }
    return (
      <footer className="footer">
        <div className="notice-container">
          <small>Made with <span className="paintit_red">❤</span>, Gatsby and <Link to="">(read more)...</Link></small>
          {/* <div>
            <UserLinks config={siteConfig} labeled />
            <Link className="userlink" to={url}>
              <GoRss />
            </Link>
          </div> */}
        </div>
      </footer>
    );
  }
}

export default Footer;
