import React from "react";
import Link from "gatsby-link";
import { graphql } from "gatsby";
import Layout from "../../layout";
import PostListing from "../PostListing/PostListing";
import config from "../../../data/SiteConfig";
import typography from "../../utils/typography"
const rhythm = typography.rhythm
const scale = typography.scale

class BigHeader extends React.Component {
    render() {
        const bgColors = { "Dark": "#333",
        "Light": "#fff",
        };
        let header
        // Check if the location is either the front page or a tags page.
        // If so, use a big header, otherwise use a smaller one.
          header = (
            <Link
              style={{
                textDecoration: "none",
                boxShadow: "none",
                color: "inherit",
                textAlign: 'center'
              }}
              to="/"
            >
              <h1
                style={{
                  ...scale(1),
                  background:bgColors.Dark,
                  color:bgColors.Light,
                  marginBottom: rhythm(1),
                  marginTop: 0,
                }}
              >
                {config.siteTitle}
              </h1>
            </Link>
    
          )

          return (
              <div>{header}</div>
          );
    }
}

export default BigHeader;