module.exports = {
  siteTitle: "Wrapcode", // Site title.
  siteTitleShort: "Wrapcode", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "Wrapcode", // Alternative site title for SEO.
  siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
  siteUrl: "https://wrapcode.com", // Domain of your website without pathPrefix.
  pathPrefix: "/wrapcode", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription: "Cloud computing, Bots, DevOps and more", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  //siteFBAppID: "1825356251115265", // FB Application ID for using app insights
  googleAnalyticsID: "UA-33745969-1", // GA tracking ID.
  disqusShortname: "wrapcode", // Disqus shortname.
  postDefaultCategoryID: "common", // Default category for posts.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.

  author :{
    fullName : "Rahul",
    userName: "Rahul",
    url : "https://fullstackdude.com",
    location: "India",
    email: "rahulpp@live.com",
    avatar: "https://www.gravatar.com/avatar/25fbed561d6e05fbb62414ddf1b8bf19,jpg?s=200",
    description : "Yeah, I like animals better than people sometimes... Especially dogs. Dogs are the best. Every time you come home, they act like they haven't seen you in a year. And the good thing about dogs... is they got different dogs for different people.",
    sameAs : [
      "https://www.facebook.com/nullobj",
      "https://www.linkedin.com/in/rahulpp/",
      "https://twitter.com/_rahulpp",
      "https://github.com/r4hulp",
      "http://instagram.com/instaraah"
    ]
  },
  userName: "Rahul", // Username to display in the author segment
  fullName: "Rahul Patil",
  userTwitter: "_rahulpp", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "Internet", // User location to display in the author segment.
  userAvatar: "https://api.adorable.io/avatars/150/test.png", // User avatar to display in the author segment.
  userDescription:
    "Yeah, I like animals better than people sometimes... Especially dogs. Dogs are the best. Every time you come home, they act like they haven't seen you in a year. And the good thing about dogs... is they got different dogs for different people.", // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      label: "GitHub",
      url: "https://github.com/r4hulp/",
      iconClassName: "fa fa-github"
    },
    {
      label: "Twitter",
      url: "https://twitter.com/_rahulpp",
      iconClassName: "fa fa-twitter"
    },
    {
      label: "Email",
      url: "mailto:rahulpp@live.com",
      iconClassName: "fa fa-envelope"
    }
  ],
  headline : "Hi, I am Rahul. I write about",
  blogSkills: [{
    name: "Azure"
  },
  {
    name: "Bots"
  },
  {
    name: "JAMStack"
  },
  {
    name: "AI"
  },
  {
    name: "DevOps"
  }
  ],
  copyright: "Copyright © 2018.", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#c62828", // Used for setting manifest and progress theme colors.
  backgroundColor: "#e0e0e0" // Used for setting manifest background color.
};
